export enum AccountSettingsFormStep {
  ContactInformation = 'Contact Information',
  Interests = 'Interests',
  LoginPassword = 'Login / Password',
  PersonalInformation = 'Personal Information',
  ProfessionalInformation = 'Professional Information',
  Profile = 'Profile',
  SitePreferences = 'Site Preferences',
  UserDataReviewConfirmation = 'User Data Review Confirmation',
}
